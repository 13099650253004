<template>
    <main>
        <b-overlay :show="loading">
            <!-- <form-filter-export :type_export="['excel']" @onExport="exportSales">
        <template #default>
          <b-form-group v-if="!isSales" label="Sales " class="mr-2">
            <v-select :options="salesOptions" v-model="dataExport.sales_id" label="text" :reduce="option => option.value"></v-select>
          </b-form-group>
          <b-form-group label="Konsumen (opsional)">
            <v-select :options="konsumenOptions" v-model="dataExport.konsumen_id" label="text" :reduce="option => option.value"></v-select>
          </b-form-group>
        </template>
</form-filter-export> -->
            <b-card>
                <!-- <b-row class="align-items-center">
                    <b-col sm="12" md="6" class="my-1">
                        <section v-if="allowCreate()" class="d-flex align-items-center">
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary"
                            @click.prevent="$router.push('/transaksi-penjualan-toko')">
                            <feather-icon icon="ShoppingBagIcon" class="mr-50" />
                            Toko
                        </b-button>
                        </section>
                    </b-col>

                    <b-col sm="12" md="6" class="d-flex justify-content-end">
                        <h2 class="text-success text-right">
                            Total Uang Masuk:
                            <strong>Rp {{ formatRupiah(this.masuk) }}</strong>
                        </h2>
                    </b-col>
                </b-row> -->

                <b-row>
                    <b-col xl="2" lg="2" md="4" sm="4" class="">
                        <b-form-group class="mb-0" :label="$t('Per page')" label-for="perPageSelect">
                            <b-form-select id="perPageSelect" size="sm" v-model="perPage" :options="pageOptions" />
                        </b-form-group>
                    </b-col>
                    <!-- <b-col md="4" sm="8" class="">
              <b-form-group :label="$t('Sort')"   label-for="sortBySelect"
                class="mb-0">
                <b-input-group >
                  <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select v-model="sortDesc"  :disabled="!sortBy" class="w-25">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col> -->
                    <b-col xl="10" lg="10" md="8" sm="8" class="">
                        <b-form-group :label="$t('Filter')" label-for="filterInput" class="mb-0">
                            <b-input-group>
                                <b-form-input id="filterInput" size="sm" v-model="filter" type="search"
                                    placeholder="Tekan enter untuk cari" @keydown.enter.prevent="getTransaksi()" />
                                <b-input-group-append>
                                    <b-button :disabled="!filter" size="sm" @click="
                                        filter = '';
                                    getTransaksi();
                                    ">
                                        Clear
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-if="isFinance || isOwner || isCEO">
                    <b-col class="mt-1" xl="3" lg="3" md="3" sm="12">
                        <b-form-group label="Status Bayar" label-for="status_bayar" class="mb-0">
                            <b-input-group>
                                <b-form-select size="sm" id="status_bayar" v-model="status_bayar"
                                    :options="optstatus_bayar" class="" @input="getTransaksi()">
                                    <template #first>
                                        <b-form-select-option :value="null">-- Semua --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col class="mt-1" xl="3" lg="3" md="3" sm="12">
                        <b-form-group label="Status" label-for="statuskirim" class="mb-0">
                            <b-input-group>
                                <b-form-select size="sm" id="statuskirim" v-model="statuskirim"
                                    :options="optstatuskirim" class="" @input="getTransaksi()">
                                    <template #first>
                                        <b-form-select-option :value="null">-- Semua --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col class="mt-1" xl="3" lg="3" md="3" sm="12">
                        <b-form-group label="Daftar yang sudah masuk ke akun dan kas" label-for="is_accounted"
                            class="mb-0">
                            <b-input-group>
                                <b-form-select size="sm" id="is_accounted" v-model="is_accounted"
                                    :options="optis_accounted" class="" @input="getTransaksi()">
                                    <template #first>
                                        <b-form-select-option :value="null">-- Semua --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col class="mt-1" xl="3" lg="3" md="3" sm="12">
                        <b-form-group label="Pilih Affiliator" label-for="is_member" class="mb-0">
                            <b-input-group>
                                <b-form-select size="sm" id="is_member" v-model="is_member" :options="optis_member"
                                    class="" @input="getTransaksi()">
                                    <template #first>
                                        <b-form-select-option :value="null">-- Semua --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-if="isCabang || isMarketing">
                    <b-col class="mt-1" xl="4" lg="4" md="4" sm="12">
                        <b-form-group label="Status Bayar" label-for="status_bayar" class="mb-0">
                            <b-input-group>
                                <b-form-select size="sm" id="status_bayar" v-model="status_bayar"
                                    :options="optstatus_bayar" class="" @input="getTransaksi()">
                                    <template #first>
                                        <b-form-select-option :value="null">-- Semua --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col class="mt-1" xl="4" lg="4" md="4" sm="12">
                        <b-form-group label="Status" label-for="statuskirim" class="mb-0">
                            <b-input-group>
                                <b-form-select size="sm" id="statuskirim" v-model="statuskirim"
                                    :options="optstatuskirim" class="" @input="getTransaksi()">
                                    <template #first>
                                        <b-form-select-option :value="null">-- Semua --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col class="mt-1" xl="4" lg="4" md="4" sm="12">
                        <b-form-group label="Status" label-for="typeTransaksi" class="mb-0">
                            <b-input-group>
                                <b-form-select size="sm" id="typeTransaksi" v-model="typeTransaksi"
                                    :options="opttypetransaksi" class="" @input="getTransaksi()">
                                    <template #first>
                                        <b-form-select-option :value="null">-- Semua --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" class="mt-1">
                        <b-table striped small hover responsive :per-page="perPage" :items="items" :fields="fields"
                            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                            <template #cell(no)="{ index }">
                                {{ index + 1 }}
                            </template>
                            <template #cell(no_invoice)="{ item }">
                                {{ item.no_invoice ? item.no_invoice : "-" }}
                            </template>
                            <template #cell(alamat)="{ item }">
                                {{ item.customer.data_pengiriman.alamat + ', Desa/Kel ' +
                                    item.customer.data_pengiriman.desa.name
                                }}<br>
                                {{ 'Kec. ' + item.customer.data_pengiriman.kecamatan.name + ', ' +
                                    item.customer.data_pengiriman.kab_kota.name + ', '
                                }}
                                {{ item.customer.data_pengiriman.provinsi.name + ' ' +
                                    item.customer.data_pengiriman.desa.postal_code
                                }}
                            </template>
                            <template #cell(order)="{ item }">
                                <template v-for="(data, index) in item.item.slice(0, 3)">
                                    <span @click="detailorder">
                                        {{ data.varian ? data.varian + ' (x' + data.qty + ')' : data.barang_nama + ' (x'
                                            + data.qty + ')' }}
                                        <span v-if="index < item.item.slice(0, 3).length - 1">, </span>
                                    </span>
                                </template>
                                <!-- <b-list-group>
                                    <template v-for="(data, index) in item.item">
                                        <b-list-group-item>{{ data.varian ? data.varian + ' (x' + data.qty + ')' :
                                            data.barang_nama + ' (x'
                                            + data.qty + ')' }}
                                            <span v-if="index < item.item.length - 1">, </span></b-list-group-item>
                                    </template>
                                </b-list-group> -->
                            </template>
                            <template #cell(status_bayar)="{ item }">
                                <b-badge v-if="item.status_bayar == 'lunas'" variant="success">LUNAS</b-badge>
                                <b-badge v-else variant="danger">BELUM LUNAS</b-badge>
                            </template>
                            <template #cell(payment_option)="{ item }">
                                <b-badge v-if="item.payment_option == 'manual_transfer'" variant="warning">Manual
                                    Transfer</b-badge>
                                <b-badge v-else-if="item.payment_option == 'pg_midtrans'"
                                    variant="success">Otomatis</b-badge>
                                <b-badge v-else variant="danger">COD</b-badge>
                            </template>
                            <template #cell(status)="{ item }">
                                <b-badge v-if="item.status == 'selesai'" variant="success">SELESAI</b-badge>
                                <b-badge v-else-if="item.status == 'diproses'" variant="warning">DIPROSES</b-badge>
                                <b-badge v-else-if="item.status == 'dikirim'" variant="primary">DIKIRIM</b-badge>
                                <b-badge v-else-if="item.status == 'diterima'" variant="info">DITERIMA</b-badge>
                                <b-badge v-else-if="item.status == 'dibatalkan'" variant="danger">DIBATALKAN</b-badge>
                                <b-badge v-else variant="secondary">PENDING</b-badge>
                            </template>
                            <template #cell(rincian)="{ item }">
                                {{ item.rincian[0] ? item.rincian[0].nama_barang : "-" }}
                            </template>
                            <template #cell(waktu_transaksi)="{ item }">
                                <strong>{{ humanDateTime(item.waktu_transaksi) }}</strong>
                            </template>
                            <template #cell(uang_masuk)="{ item }">
                                <strong>Rp {{ formatRupiah(item.uang_masuk) }}</strong>
                            </template>
                            <template #cell(total_bayar)="{ item }">
                                <strong>Rp {{ formatRupiah(item.total_bayar) }}</strong>
                            </template>
                            <template #cell(transaction_type)="{ item }">
                                <strong>
                                    {{
                                        item.transaction_type == "jasa" ? "Jasa" : "Barang"
                                    }}</strong>
                            </template>

                            <template #cell(action)="{ item }">
                                <b-button size="sm" variant="warning" @click.prevent="
                                    $router.push(`/pesanan/manual-order-detail/${item.id}`)
                                    ">
                                    <feather-icon icon="EyeIcon" />
                                </b-button>
                                <b-button v-if="
                                    item.transaction_type == 'jasa' &&
                                    item.status != 'dibatalkan'
                                " size="sm" variant="outline-primary" @click.prevent="modalTerapis(item)"
                                    v-b-tooltip.hover.top="'Pilih Terapis'">
                                    <feather-icon icon="UserIcon" />
                                </b-button>
                                <b-button v-if="
                                    item.status == 0 &&
                                    allowUpdate() &&
                                    item.tak_tertagih == 0 &&
                                    item.bayar == 0
                                " size="sm" variant="outline-warning" @click.prevent="
                                    $router.push(`/transaksi-penjualan/edit/${item.id}`)
                                    ">
                                    <feather-icon icon="EditIcon" />
                                </b-button>
                            </template>
                            <template #cell(actionFinance)="{ item }">
                                <b-button size="sm" variant="warning" @click.prevent="
                                    $router.push(`/transaksi-penjualan/detail/${item.id}`)
                                    ">
                                    <feather-icon icon="EyeIcon" />
                                </b-button>
                                <b-button title="Konfirmasi Pembayaran Manual" v-if="item.status_bayar != 'lunas'"
                                    size="sm" variant="primary" @click="aksiKonfirmasiawal(item)">
                                    <feather-icon icon="ListIcon" />
                                </b-button>
                                <b-button title="Cek Pembayaran Otomatis" v-if="
                                    item.status_bayar != 'lunas' &&
                                    item.payment_option == 'pg_midtrans'
                                " size="sm" variant="info" @click="aksiKonfirmasipg(item)">
                                    <feather-icon icon="DollarSignIcon" />
                                </b-button>
                                <!-- <b-button title="Konfirmasi Pembayaran" v-if="item.status_bayar != 'lunas' && item.status != 'dibatalkan'" size="sm" variant="primary" @click="aksiKonfirmasi(item)">
                    <feather-icon icon="CheckIcon" />
                  </b-button> -->
                                <b-modal hide-backdrop no-close-on-esc no-close-on-backdrop v-model="showKonfirmasi"
                                    id="modal-konfirmasi" ok-variant="secondary" centered
                                    :title="'Konfirmasi Pembayaran ' + formkonfirmasi.no_invoice">
                                    <b-row>
                                        <!-- {{ this.konfirmasi != null ? this.konfirmasi : '-' }} -->
                                        <b-col xl="12" lg="12" md="12" sm="12">
                                            <!-- <b-card> -->
                                            <!-- <validation-observer ref="vkonfirmasi">
                          <b-form>
                              <b-row>
                                <b-col sm="12" md="12">
                                  <b-form-group label="Jenis Transaksi">
                                    <b-form-select
                                      v-model="transactionType"
                                      :options="[
                                        { value: 1, text: 'Debit' },
                                        { value: 2, text: 'Kredit' },
                                      ]"
                                      id="v-transaction_type"
                                      name="transaction_type"
                                    />
                                  </b-form-group>
                                </b-col>
                                <b-col sm="12" md="12" v-if="transactionType">
                                  <b-form-group label="Pilih Akun">
                                    <validation-provider #default="{ errors }" rules="required" name="id_akun">
                                      <b-form-select v-model="formkonfirmasi.id_akun" :options="id_akun" id="v-id_akun" name="id_akun" />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col sm="12" md="12">
                                  <b-form-group label="Pilih Kas">
                                    <validation-provider #default="{ errors }" rules="required" name="id_kas">
                                      <b-form-select v-model="formkonfirmasi.id_kas" :options="id_kas" id="v-id_kas" name="id_kas" />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-form> -->
                                            <!-- <b-button block>Lunas</b-button> -->
                                            <!-- </validation-observer> -->
                                            <b-button variant="primary" @click.prevent="submitkonfirmasi" block
                                                class="mb-2">
                                                Konfirmasi Lunas
                                            </b-button>
                                            <!-- </b-card> -->
                                            <!-- </b-col>
                      <b-col xl="6" lg="6" md="12" sm="12"> -->
                                            <!-- <b-card border-variant="primary">
                          
                        </b-card> -->
                                            <!-- </b-col>
                      <b-col xl="6" lg="6" md="12" sm="12"> -->
                                            <!-- <b-card> -->
                                            <b-overlay :show="loadingfoto">
                                                <h3 align="center">Bukti Transfer</h3>
                                                <template v-if="formkonfirmasi.bukti_tf">
                                                    <b-img :src="apiFile + formkonfirmasi.bukti_tf.file" alt=""
                                                        fluid-grow />
                                                </template>
                                                <template v-else>
                                                    <center>
                                                        <h3 class="text-danger">Belum diupload</h3>
                                                    </center>
                                                </template>
                                            </b-overlay>
                                            <!-- </b-card> -->
                                        </b-col>
                                    </b-row>
                                    <template #modal-footer>
                                        <section class="d-flex justify-content-end align-items-center">
                                            <b-button size="sm" variant="danger" @click="
                                                showKonfirmasi = false;
                                            getTransaksi();
                                            ">
                                                Keluar
                                            </b-button>
                                        </section>
                                    </template>
                                </b-modal>
                            </template>
                        </b-table>
                    </b-col>

                    <b-col cols="12">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"
                            size="sm" class="my-0" />
                    </b-col>
                </b-row>
            </b-card>
        </b-overlay>
    </main>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormFilterExport from "@core/components/form-filter-export/FormFilterExport.vue";
import {
    BTable,
    BCard,
    VBTooltip,
    BImg,
    BForm,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    BOverlay, BListGroup, BListGroupItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import mixin from "./mixin";

export default {
    mixins: [mixin],
    components: {
        ToastificationContent,
        FormFilterExport,
        VBTooltip,
        BModal,
        BDropdown,
        BDropdownItem,
        BTable,
        BCard,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BFormSelectOption,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BFormCheckbox,
        BOverlay,
        vSelect,
        ValidationProvider,
        ValidationObserver,
        BForm, BListGroup, BListGroupItem,
        BImg,
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            showModalEdit: false,
            showModalAdd: false,
            showModalTerapis: false,
            loadingTerapis: false,
            itemTransaksiTerapis: {},
            id: null,
            form: {
                blok: null,
                gudang_id: null,
            },
            dataGudang: [],
            listTerapis: [],
            perPage: 10,
            pageOptions: [10, 30, 50],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            infoModal: {
                id: "info-modal",
                title: "",
                content: "",
            },
            dataExport: {
                konsumen_id: null,
                sales_id: null,
            },
            fields: [
                {
                    key: "no",
                    label: "No",
                },
                { key: "no_invoice", label: "No. Invoice", sortable: true },
                { key: "nama", label: "Konsumen" },
                // { key: "tanggal", label: "Tanggal Transaksi", sortable: true },
                { key: "status_bayar", label: "Status Bayar" },
                { key: "status", label: "Status Pengiriman" },
                { key: "action", label: "#" },
            ],
            fieldsTerapis: [
                {
                    key: "no",
                    label: "No",
                },
                { key: "nama_lengkap", label: "Nama Lengkap", sortable: true },
                { key: "no_hp", label: "No. HP" },
                { key: "alamat", label: "Alamat" },
                { key: "on_progress_orders", label: "Sedang Orderan" },
                { key: "completed_orders", label: "Selesai Orderan" },
                { key: "action", label: "#" },
            ],
            items: [],
            swalOptions: {
                title: "Pindahkan Stok Retur ke Mobil?",
                text: `Stok Barang Retur akan kembali ke Stok Mobil Anda`,
                icon: "info",
                showCancelButton: true,
                confirmButtonText: "Retur Barang",
                cancelButtonText: "Batal",
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            },
            salesOptions: [],
            konsumenOptions: [],
            loading: false,
            loadingfoto: false,
            showKonfirmasi: false,
            formkonfirmasi: {
                no_invoice: null,
                bukti_tf: {
                    file: null,
                },
            },
            konfirmasi: null,
            id_akun: [],
            id_kas: [],
            transactionType: null,
            debitAkun: [],
            kreditAkun: [],
            foto: null,
            status_bayar: null,
            optstatus_bayar: [
                { text: "Lunas", value: "lunas" },
                { text: "Belum Lunas", value: "belum_lunas" },
            ],
            statuskirim: null,
            optstatuskirim: [
                { value: "selesai", text: "Selesai" },
                { value: "diproses", text: "Diproses" },
                { value: "dikirim", text: "Dikirim" },
                { value: "dibatalkan", text: "Dibatalkan" },
                { value: "pending", text: "Pending" },
            ],
            typeTransaksi: null,
            opttypetransaksi: [
                { text: "Member", value: "member" },
                { text: "Non Member", value: "non-member" },
            ],
            member_id: null,
            is_accounted: null,
            optis_accounted: [
                { text: "Ya", value: 1 },
                // { text: 'Tidak', value: != 1 },
            ],
            masuk: null,
            optis_member: null,
            is_member: null,
            detailorder: false,
        };
    },
    computed: {
        isKreditAkun() {
            return this.transactionType == 2;
        },
        isDebitAkun() {
            return this.transactionType == 1;
        },
        totalPenjualan() {
            if (!this.items || this.items.length < 1) {
                return 0;
            }

            return this.items.reduce(
                (total, item) => (total += parseInt(item.uang_masuk)),
                0
            );
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => ({ text: f.label, value: f.key }));
        },
        apiFile() {
            return "https://api-heykama.notive.my.id/";
        },
    },
    watch: {
        truncateString(str, maxLength) {
            if (str.length > maxLength) {
                return str.substring(0, maxLength - 3) + '...';
            } else {
                return str;
            }
        },
        transactionType(val) {
            if (val) {
                if (val == 1) {
                    this.id_akun = this.debitAkun;
                } else {
                    this.id_akun = this.kreditAkun;
                }
            }
        },
        perPage(value) {
            if (value) {
                this.getTransaksi();
            }
        },
        // filter(keyword) {
        //  if (keyword.length > 2 || keyword.length == 0) {
        //    this.getTransaksi();
        // }
        //},
        currentPage(val) {
            if (val) {
                this.getTransaksi();
                // this.items
            }
        },
    },
    methods: {
        submitkonfirmasi2() {
            // this.$refs.vkonfirmasi.validate().then((success) => {
            //   if (success) {
            this.label = "Loading...";
            let payload2 = {
                no_invoice: this.formkonfirmasi.no_invoice,
            };
            this.$store
                .dispatch("transaksiOnline/tandaiLunas", payload2)
                .then(() => {
                    this.showKonfirmasi = false;
                    this.displaySuccess({
                        text: "Berhasil dikonfirmasi",
                    });
                    this.getTransaksi();
                    // this.formKonfirmasi = null
                })
                .catch((e) => {
                    this.label = "Submit";
                    this.displayError(e);
                    return false;
                });
            //   }
            // });
        },
        submitkonfirmasi() {
            // this.$refs.vkonfirmasi.validate().then((success) => {
            //   if (success) {
            this.label = "Loading...";
            // let join = this.pilihteritori;
            // let string = join.join(",");
            // var array = JSON.parse("[" + string + "]");
            let payload = {
                no_invoice: this.formkonfirmasi.no_invoice,
                // nama_cabang: this.form.nama_cabang,
                akun_id: this.formkonfirmasi.id_akun,
                kas_id: this.formkonfirmasi.id_kas,
            };
            let payload2 = {
                no_invoice: this.formkonfirmasi.no_invoice,
            };
            this.$store
                .dispatch("transaksiOnline/tandaiLunas", payload2)
                .then(() => {
                    this.showKonfirmasi = false;
                    this.displaySuccess({
                        text: "Berhasil dikonfirmasi",
                    });
                    this.getTransaksi();
                    // this.formKonfirmasi = null
                })
                .catch((e) => {
                    this.label = "Submit";
                    this.displayError(e);
                    return false;
                });
            //   }
            // });
        },
        aksiKonfirmasiawal(item) {
            this.konfirmasi = item.id;
            this.formkonfirmasi = {
                no_invoice: item.no_invoice ? item.no_invoice : "-",
                bukti_tf: {
                    file: item.bukti_tf ? item.bukti_tf.file : null,
                },
            };
            // this.submitkonfirmasi2()
            // console.log('AJI', this.foto)
            this.getTransaksiId();
            this.showKonfirmasi = true;
        },
        aksiKonfirmasipg(item) {
            // this.konfirmasi = item.id;
            // this.formkonfirmasi = {
            //   no_invoice: item.no_invoice ? item.no_invoice : "-",
            //   bukti_tf: {
            //     file: item.bukti_tf ? item.bukti_tf.file : null,
            //   },
            // };
            let payload = {
                no_invoice: item.no_invoice ? item.no_invoice : "-",
            };
            // this.$swal({
            //   title: "Anda yakin?",
            //   text: `Invoice : ${terapis.nama_lengkap} ini ditandai lunas?`,
            //   icon: "warning",
            //   showCancelButton: true,
            //   confirmButtonText: "Ya",
            //   cancelButtonText: "Batal",
            //   customClass: {
            //     confirmButton: "btn btn-success",
            //     cancelButton: "btn btn-danger ml-1",
            //   },
            //   buttonsStyling: false,
            // }).then((result) => {
            //   if (result.value) {
            this.$store
                .dispatch("transaksiOnline/getDatapg", payload)
                .then((res) => {
                    // this.formkonfirmasi = res;
                    this.getTransaksi();
                })
                .catch((err) => {
                    this.displayError(err);
                    return false;
                });
            //   }
            // });
        },
        aksiKonfirmasi(item) {
            this.konfirmasi = item.id;
            this.formkonfirmasi = {
                no_invoice: item.no_invoice ? item.no_invoice : "-",
                bukti_tf: {
                    file: item.bukti_tf ? item.bukti_tf.file : null,
                },
            };
            this.submitkonfirmasi2();
            // console.log('AJI', this.foto)
            // this.getTransaksiId();
            // this.showKonfirmasi = true;
        },
        getTransaksiId(item) {
            this.loadingfoto = true;
            let params = {
                id: this.konfirmasi,
                show_bukti_tf: 1,
                view_as_invoice: 1,
            };
            this.$store
                .dispatch("transaksiOnline/getDataById", params)
                .then((res) => {
                    // let items = JSON.parse(JSON.stringify(this.$store.state.transaksiOnline.datas));
                    // let items_total = this.$store.state.transaksiOnline.totals;
                    this.formkonfirmasi = res;
                    // this.totalRows = items_total;
                    this.loadingfoto = false;
                    // this.items = res;
                })
                .catch((err) => {
                    // console.error(err);
                    this.loadingfoto = false;
                    this.displayError(err);
                    return false;
                });
        },
        async exportSales({ tgl_awal, tgl_akhir, type_export }) {
            if (!tgl_awal || !tgl_akhir) {
                this.displayError({
                    message: "Harap isi tanggal awal dan akhir!",
                });
                return false;
            }

            // validate type_export pdf || excel
            const params = {
                tgl_awal,
                tgl_akhir,
                type_export,
            };
            if (this.isSales && this.myGudang)
                params.sales_id =
                    this.user && this.user.karyawan ? this.user.karyawan.id : null;
            if (this.dataExport.konsumen_id)
                params.konsumen_id = this.dataExport.konsumen_id;
            if (this.dataExport.sales_id) params.sales_id = this.dataExport.sales_id;

            try {
                this.loading = true;
                const response = await this.printSales(params);
                this.loading = false;
                if (type_export == "pdf") {
                    window.open(URL.createObjectURL(response));
                } else {
                    await window.open(response);
                    setTimeout(async () => {
                        // delete selected file
                        const arrFile = response.split("/");
                        const filename = arrFile[arrFile.length - 1];

                        await this.clearExportedFile(filename);
                    }, 1000);
                }
            } catch (e) {
                console.error(e);
                this.loading = false;
                this.displayError(e);
                return false;
            }
        },

        getTransaksi() {
            this.loading = true;
            // this.refreshToken()
            // if (!this.isCabang && !this.isTerapis) {
            //     const perPage = parseInt(this.perPage);
            //     const currentPage = (parseInt(this.currentPage) - 1) * perPage;
            //     var params = {
            //         search: this.filter != null ? this.filter : null,
            //         // order: "desc",
            //         start: currentPage,
            //         length: this.perPage,
            //         // metode_bayar: "manual_transfer",
            //         show_bukti_tf: 1,
            //         status_bayar: this.status_bayar != null ? this.status_bayar : null,
            //         status: this.statuskirim != null ? this.statuskirim : null,
            //         member_id: this.is_member != null ? this.is_member : null,
            //         is_accounted: this.is_accounted != null ? this.is_accounted : null,
            //     };
            // } else if (this.isTerapis) {
            //     const perPage = parseInt(this.perPage);
            //     const currentPage = (parseInt(this.currentPage) - 1) * perPage;
            //     var params = {
            //         search: this.filter != null ? this.filter : null,
            //         terapis: this.user.id,
            //         // order: "desc",
            //         start: currentPage,
            //         length: this.perPage,
            //         status_bayar: this.status_bayar != null ? this.status_bayar : null,
            //         status: this.statuskirim != null ? this.statuskirim : null,
            //         type_transaction:
            //             this.typeTransaksi != null ? this.typeTransaksi : null,
            //         // metode_bayar: "manual_transfer",
            //     };
            // } else {
            const perPage = parseInt(this.perPage);
            const currentPage = (parseInt(this.currentPage) - 1) * perPage;
            var params = {
                search: this.filter != null ? this.filter : null,
                // member_id: this.user.member_id,
                // order: "desc",
                start: currentPage,
                length: this.perPage,
                status_bayar: this.status_bayar != null ? this.status_bayar : null,
                status: this.statuskirim != null ? this.statuskirim : null,
                type_transaction:
                    this.typeTransaksi != null ? this.typeTransaksi : null,
                view_as_invoice: 1,
                // metode_bayar: "manual_transfer",
            };
            // }
            this.$store
                .dispatch("transaksiOnline/getData", params)
                .then((res) => {
                    let items = res
                    // let items = JSON.parse(
                    //     JSON.stringify(this.$store.state.transaksiOnline.datas)
                    // );
                    console.log('jem', items)
                    let items_total = this.$store.state.transaksiOnline.totals;
                    let items_masuk = this.$store.state.transaksiOnline.masuks;
                    this.items = items;
                    this.totalRows = items_total;
                    this.masuk = items_masuk;
                    this.loading = false;
                    // this.items = res;
                })
                .catch((err) => {
                    console.error(err);
                    this.loading = false;
                    this.displayError(err);
                    return false;
                });
        },
        getKas() {
            this.$store
                .dispatch("kas/getData", {})
                .then(() => {
                    let hem = JSON.parse(JSON.stringify(this.$store.state.kas.datas));
                    hem.map((item) => {
                        item.value = item.id;
                        item.text =
                            item.saldo >= 0
                                ? item.nama + " -> " + this.formatRupiah(item.saldo)
                                : item.nama + "( " + this.formatRupiah(item.saldo * -1) + " )";
                    });
                    this.id_kas = hem;
                })
                .catch((e) => {
                    this.displayError(e);
                    return false;
                });
        },
        getAkun() {
            this.$store
                .dispatch("akun/getData", {})
                .then(() => {
                    let akuns = JSON.parse(JSON.stringify(this.$store.state.akun.datas));
                    akuns.map((item) => {
                        item.value = item.id;
                        item.text =
                            item.saldo >= 0
                                ? item.nama + " -> " + this.formatRupiah(item.saldo)
                                : item.nama + "( " + this.formatRupiah(item.saldo * -1) + " )";
                    });

                    this.debitAkun = akuns.filter((akun) =>
                        [this.JENIS_AKUN.debit, this.JENIS_AKUN.debit_kredit].includes(
                            akun.jenis
                        )
                    );
                    this.kreditAkun = akuns.filter((akun) =>
                        [this.JENIS_AKUN.kredit, this.JENIS_AKUN.debit_kredit].includes(
                            akun.jenis
                        )
                    );
                })
                .catch((e) => {
                    this.displayError(e);
                    return false;
                });
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        getMember() {
            const perPage = parseInt(this.perPage);
            const currentPage = (parseInt(this.currentPage) - 1) * perPage;
            const payload = {
                search: this.filter != null ? this.filter : null,
                sebagai: "cabang",
                // order: "desc",
                // start: currentPage,
                // length: this.perPage,
                // filter_by: "nama_lengkap",
                // category_paket_id: this.filterKatPaket != null ? this.filterKatPaket.id : null,
                // paket_id: this.filterPaket != null ? this.filterPaket.id : null,
                // jadwal_id: this.filterJadwal != null ? this.filterJadwal.id : null,
                // user_id: this.user.id,
            };
            this.$store.dispatch("member/getData", payload).then(() => {
                let items = JSON.parse(JSON.stringify(this.$store.state.member.datas));
                items.map((item) => {
                    item.value = item.id;
                    item.text = item.nama_lengkap;
                });
                this.optis_member = items;
                // this.items = this.$store.state.member.datas;
                // this.totalRows = this.items.length;
            });
        },
        getTerapis() {
            this.$store
                .dispatch("terapis/getData")
                .then((res) => {
                    this.listTerapis = res;
                })
                .catch((error) => {
                    console.error("Error fetching terapis data:", error);
                });
        },
        modalTerapis(item) {
            this.itemTransaksiTerapis = item;
            this.showModalTerapis = true;
        },
        pilihTerapis(terapis) {
            this.$swal({
                title: "Anda yakin?",
                text: `Apakah yakin untuk memilih ${terapis.nama_lengkap}?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Ya",
                cancelButtonText: "Batal",
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.loadingTerapis = true;
                    let payload = {
                        transaksi_id: this.itemTransaksiTerapis.id,
                        karyawan_id: terapis.id,
                    };
                    this.$store
                        .dispatch("transaksiOnline/saveTerapis", payload)
                        .then(() => {
                            this.displaySuccess({
                                text: "Terapis Berhasil dipilih",
                            });
                            this.loadingTerapis = false;
                            this.showModalTerapis = false;
                            this.itemTransaksiTerapis = {};
                            this.getTransaksi();
                            this.getTerapis();
                        })
                        .catch((e) => {
                            this.loadingTerapis = false;
                            this.showModalTerapis = false;
                            this.itemTransaksiTerapis = {};
                            this.displayError(e);
                            return false;
                        });
                }
            });
        },
    },
    created() {
        // this.getMember();
        this.getTransaksi();
        // this.getKas();
        // this.getAkun();
        // this.getTerapis();
        if (!this.isFinance) {
            this.fields = [
                {
                    key: "no",
                    label: "No",
                },
                { key: "waktu_transaksi", label: "Tanggal Transaksi", sortable: true },
                { key: "no_invoice", label: "No. Invoice", sortable: true },
                { key: "customer.nama", label: "Nama" },
                { key: "customer.no_hp", label: "No. Tlp" },
                { key: "alamat", label: "Alamat" },
                { key: "order", label: "Order" },
                { key: "uang_masuk", label: "Total Order" },
                // { key: "status", label: "Status Pengiriman" },
                { key: "action", label: "#" },
            ];
        } else if (this.isFinance) {
            this.fields = [
                { key: "actionFinance", label: "#", stickyColumn: true },
                {
                    key: "no",
                    label: "No",
                },
                { key: "waktu_transaksi", label: "Tanggal Transaksi", sortable: true },
                { key: "no_invoice", label: "No. Invoice", sortable: true },
                { key: "member_id", label: "Affiliator" },
                // { key: "nama", label: "Konsumen" },
                { key: "status_bayar", label: "Status Bayar" },
                { key: "status", label: "Status Pengiriman" },
                { key: "uang_masuk", label: "Uang Masuk" },
                { key: "total_bayar", label: "Total Bayar" },
                { key: "transaction_type", label: "Tipe Transaksi" },
                { key: "payment_option", label: "Metode Pembayaran" },
            ];
        }

        // this.getData();
    },
};
</script>